<template>
<div id="talent" class="talent pb-24 flex w-full bg-primary">
  <div class="grid grid-cols-12 gap-0 w-full">
    <div class="col-span-12">
      <div class="pt-20 md:pl-24">
        <div class="sm:hidden">
          <span class="text-lead text-left font-black font-serif">Talents</span>
          <span class="pl-6 italic text-xl">{{ props.event.talentDescriptionLeft }}</span> <span class="dash pr-2">————</span>
          <span class="italic text-xl">{{ props.event.talentDescriptionRight }}</span>
        </div>
        <div class="md:hidden">
          <div class="text-lead-mobile-bigger sm:text-center sm:w-full font-black font-serif">Talents</div>
          <div class="sm:text-center italic text-xl sm:text-lg pt-4">{{ props.event.talentDescriptionLeft }} <span
            class="dash pr-2">————</span> {{ props.event.talentDescriptionRight }}
          </div>
        </div>
      </div>

      <div v-for="stage in stages">
        <MusicStage v-if="stage.type == 'music'" :stage="stage" :talents="stage.musicTalents"/>

        <TalkshowStage v-if="stage.type == 'talkshow'" :position="getDirection(stage.id)" :stage="stage" :speakers="stage.speakerTalents" :hosts="stage.hostTalents"/>
      </div>
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import MusicStage from "./talent/MusicStage.vue";
import TalkshowStage from "./talent/TalkshowStage.vue"
import {ref} from "vue";

const props = defineProps<{
  event: any,
}>()

const query = new URLSearchParams({
  "filter[event]" : props.event.id,
  "sort": "order",
  "fields" : "id, name, type, musicTalents.*.*, hostTalents.*.*, speakerTalents.*.*",
});

const data = await fetch(`${import.meta.env.PUBLIC_API_URL}/items/EventStage?${query.toString()}`)
  .then((response) =>
    response.json()
  );

const stages: any[] = data.data;
const talkshows = stages.filter(s => s.type == "talkshow");
const talkshowDirection = ref("right");

for (const talkshow of talkshows) {
  talkshow.direction = talkshowDirection.value;

  if (talkshowDirection.value == "right") {
    talkshowDirection.value = "left";
  } else {
    talkshowDirection.value = "right";
  }
}

const getDirection = (id: number) => {
  return talkshows.find(t => t.id == id).direction;
}

</script>

<style>
  .talent {
    scroll-margin-top: 130px;
  }

  .dash {
    letter-spacing: -8pt;
  }
</style>
