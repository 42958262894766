<template>
  <div class="flex justify-center w-full mt-4 py-8 text-center">
    <Divider bgColor="#FF644F" :text="stage.name"/>
  </div>

  <div class="flex justify-center w-full gap-16 pb-8">
    <div id="2d" class="text-[1.25rem] font-bold cursor-pointer jump" :class="{'text-white': active == 'text'}" @click="active = 'text'"><span v-if="active == 'text'">• </span>Text View</div>
    <div id="3d" class="text-[1.25rem] font-bold cursor-pointer jump" :class="{'text-white': active == 'image'}" @click="active = 'image'"><span v-if="active == 'image'">• </span>Image View</div>
  </div>

  <div class="justify-center text-center mx-20 sm:mx-10">
    <span v-if="active == 'text'" v-for="talent in alltalents" class="h-[21px] gap-[1rem] pt-4 cursor-pointer font-bold text-[2rem] uppercase sm:text-[1.25rem] leading-[3rem] sm:leading-[2rem] break-words hyphens-auto" @click="clickTalent(talent)">
      <span class="hover:text-white jump inline-block">{{talent.name}}</span><span v-if="talent.id !== talents[talents.length-1].id"> | </span>
    </span>
  </div>

  <div v-if="active == 'image'" v-for="talents in split_talents" class="sm:hidden flex justify-center gap-[1rem] pt-4">
    <div v-for="talent in talents">
      <div class="bg-white p-3 cursor-pointer w-[16vw]" @click="clickTalent(talent) ">
        <img class="w-full" :src="`${url}/assets/${talent.images[0].directus_files_id}?key=webp`" alt=""/>
        <div class="pt-3 w-full text-center font-bold">{{ talent.name }}</div>
        <div class="pb-2 w-full text-center">{{ talent.instansi }}</div>
      </div>
    </div>
  </div>


  <div v-if="active == 'image'" v-for="talents in split_talents_mobile"  class="md:hidden flex justify-center gap-[1rem] pt-4">
    <div v-for="talent in talents"  class="bg-white p-3 w-[40%] cursor-pointer" @click="clickTalent(talent)">
      <div>
        <img class="w-full" :src="`${url}/assets/${talent.images[0].directus_files_id}?key=webp`" alt=""/>
        <div class="pt-3 w-full text-center font-bold">{{ talent.name }}</div>
        <div class="pb-2 w-full text-center">{{ talent.instansi }}</div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import Divider from "../../utils/Divider.vue";
import {ref} from "vue";
import {useGlobalState} from "../../../utils/store";
const props = defineProps<{
  talents: any[],
  stage: any,
}>();


const url = import.meta.env.PUBLIC_API_URL;
const chunkSize = 5;
const chunkSizeMobile = 2;
const alltalents = ref([]);
let split_talents = ref([]);
let active = ref("image");
let tulus = ref();

alltalents.value = props.talents.sort((a, b) => a.order - b.order);
tulus.value = alltalents.value.find(a => a.name == "TULUS");
alltalents.value = props.talents.filter(a => a.name != "TULUS");

for (let i = 0; i < alltalents.value.length; i += chunkSize) {
  let chunk: any[] = [];

  chunk = alltalents.value.slice(i, i + chunkSize);

  split_talents.value = split_talents.value.concat([chunk]);
}

let split_talents_mobile = ref([]);
for (let i = 0; i < alltalents.value.length; i += chunkSizeMobile) {
  let chunk: any[] = [];

  chunk = alltalents.value.slice(i, i + chunkSizeMobile);

  split_talents_mobile.value = split_talents_mobile.value.concat([chunk]);
}

if (tulus.value) {
  alltalents.value.unshift(tulus.value);
  split_talents.value.unshift([tulus.value]);
  split_talents_mobile.value.unshift([tulus.value]);
}

const state = useGlobalState();

const clickTalent = (talent: any) => {
  // state.isOpen.value = true;
  // state.name.value = talent.name;
  // state.description.value = talent.description;
  // state.images.value = talent.images;
  // state.location.value = talent.area?.name;
  // state.time.value = talent.time;
  // state.video.value = talent.youtubeId;
  // state.instagram.value = talent.instagramLink;
  // state.type.value = "talent";
  // window.dialog.showModal();
}
</script>


<style lang="scss">
  .jump:hover {
    transform: translate(0, -4px);
    transition-duration: 0.2s;
  }
</style>
