<template>
  <div class="flex justify-center w-full mt-4 py-12 text-center">
    <Divider bgColor="#FF644F" :text="stage.name"/>
  </div>

  <div class="sm:hidden flex justify-center pt-4 mb-2 px-36 gap-[4.375rem]">
    <div class="w-[50vw]" v-if="props.position == 'left'">
      <img class="w-full sticky top-[9.5rem] cursor-pointer object-cover" :src="current_image+'?key=webp'" alt="" @click="clickTalent"/>
    </div>
    <div class="w-[40vw]" :class="{'text-left' : position == 'left', 'text-right' : position == 'right',}">
      <div v-if="speakers.length != 0" class="pt-12 pb-6 text-xl italic">
        Keynote Speakers
      </div>
      <div v-for="talent in allspeakers" class="text-[2rem] font-bold pt-1 cursor-pointer hover:underline" @click="setCurentTalent(talent)">
        {{ talent.name }}<span v-if="talent.instansi" class="font-normal"> | {{ talent.instansi }}</span>
      </div>

      <div v-if="hosts.length != 0" class="pt-12 pb-6 text-xl italic">
        Hosts
      </div>
      <div v-for="talent in allhosts" class="text-[2rem] font-bold pt-1 cursor-pointer hover:underline" @click="setCurentTalent(talent)">
        {{ talent.name }}<span v-if="talent.instansi" class="font-normal"> | {{ talent.instansi }}</span>
      </div>
    </div>
    <div class="w-[50vw]"  v-if="props.position == 'right'">
      <img class="w-full sticky top-[9.5rem] cursor-pointer object-cover" :src="current_image+'?key=webp'" alt="" @click="clickTalent"/>
    </div>
  </div>

  <div class="md:hidden flex flex-col justify-center pt-4 mb-2">
    <div class="w-full">
      <img class="w-full cursor-pointer" :src="current_image+'?key=webp'" alt="" @click="clickTalent"/>
    </div>
    <div class="text-left mx-10 pb-1">
      <div class="pt-8 pb-1 text-xl italic w-full">
        Keynote Speakers
      </div>

      <div v-for="talent in allspeakers" class="text-[1.25rem] font-bold pt-1 cursor-pointer hover:underline" @click="setCurentTalent(talent)">
        {{ talent.name }}<span v-if="talent.instansi" class="font-normal"> | {{ talent.instansi }}</span>
      </div>

      <div v-if="hosts.length != 0" class="pt-8 pb-1 text-xl italic">
        Hosts
      </div>

      <div v-for="talent in allhosts" class="text-[1.25rem] font-bold pt-1 cursor-pointer hover:underline" @click="setCurentTalent(talent)">
        {{ talent.name }}<span v-if="talent.instansi" class="font-normal"> | {{ talent.instansi }}</span>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import Divider from "../../utils/Divider.vue";
import {ref} from "vue";
import {useGlobalState} from "../../../utils/store";
const props = defineProps<{
  position: string,
  speakers: any[],
  hosts: any[],
  stage: any,
}>();

const allspeakers = ref([]);
const allhosts = ref([]);

allspeakers.value = props.speakers.sort((a, b) => a.order - b.order);
allhosts.value = props.hosts.sort((a, b) => a.order - b.order);

const url = import.meta.env.PUBLIC_API_URL;

const current_image = ref(url + "/assets/" + allspeakers?.value[0]?.images?.[0]?.directus_files_id);
const current_talent = ref(allspeakers?.value[0]);

const setCurentTalent = (talent: any) => {
  current_image.value = url + "/assets/" + talent.images[0].directus_files_id;
  current_talent.value = talent;
}

const state = useGlobalState();

const clickTalent = () => {
  // state.isOpen.value = true;
  // state.name.value = current_talent.value?.name;
  // state.description.value = current_talent.value?.description;
  // state.images.value = current_talent.value?.images;
  // state.location.value = current_talent.value?.area?.name;
  // state.time.value = current_talent.value?.time;
  // state.video.value = current_talent.value?.youtubeId;
  // state.instagram.value = talent.instagramLink;
  // state.type.value = "talent";
  // window.dialog.showModal();
}
</script>

